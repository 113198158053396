import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import * as style from "./index.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Carousel, Button, Row , Col} from "react-bootstrap";

// P5
import Sketch from "../components/sketch";
import sketch from "../components/scripts/ramifications";

const PageHeader = styled.h1`
  text-align: center;
`;

const GalleryPage = ({ data }) => {
  // const allShows = data.allMarkdownRemark.edges.nodes;
  return (
    <Layout>
      <SEO
        title="art in common"
        description="Online Gallery for art in commons"
        pathname="/gallery"
      />

      <Sketch sketch={sketch} />

      <PageHeader className={style.heading}>
        <div>Online Gallery</div>
      </PageHeader>
      <Container>
        <Carousel key="shows">
          {data.allMarkdownRemark.edges.map((thisShow) => (
            <Carousel.Item active>
              <Img
                fluid={thisShow.node.frontmatter.featimg.childImageSharp.fluid}
                alt={thisShow.node.frontmatter.author}
                className="d-block w-100"
              />
              <Carousel.Caption>
                <h2>{thisShow.node.frontmatter.title}</h2>
                <h4 className="d-none d-lg-block">
                  {thisShow.node.frontmatter.author}
                </h4>
                <p className="d-none d-md-block">
                  {thisShow.node.frontmatter.date}
                </p>
                <p className="d-none d-xl-block">{thisShow.node.excerpt}</p>
                {/* <p>{thisShow.node.frontmatter.link}</p> */}
              <p>  <Button href={thisShow.node.frontmatter.link} variant="info">
                  enter VR
                </Button></p>
                <p><Button href={thisShow.node.fields.slug} variant="info">
                  more 
                </Button></p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
      <Container className="py-3">
        <Row>
          <Col>
            <section className={style.translated}>
              <div>
                Welcome to the online gallery, the first room of the platform
                artincommon.de, a network of relationships and encounters, that
                works through the virtual space, into the physical space.
                Currently you can see the first exhibition of the online gallery
                Ego Circular Spaces by Julia-Lena Lippoldt and Luis Negrón van
                Grieken aka CocoLippo.
              </div>
            </section>
          </Col>
          <Col lg={6}>
            <section className={style.entertext}>
              <span className="py-4">
                Herzlich willkommen in der online Gallerie, dem ersten Raum der
                Plattform artincommon.de, ein Netzwerkgeflecht aus Beziehungen
                und Begegnungen, dass über den virtuellen Raum, in den
                physischen Raum einwirkt.
              </span>
            </section>
          
        </Col>
      </Row>
      </Container>
    </Layout>
  );
};

export default GalleryPage;

/// FROM MD
export const query = graphql`
  {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/gallery/" } }) {
      edges {
        node {
          id
          frontmatter {
            featimg {
              childImageSharp {
                id
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author
            date(fromNow: true)
            subject
            title
            link
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`;
